import React from "react";
import Footer from "../../components/Footer";
import AboutUS from "../../section/AboutOverAll/AboutUS/AboutUs";
import AboutUSsectio1 from "../../section/AboutOverAll/AboutUS/Aboutsection2";

const Aboutus = () => {
  return (
    <div>
      <AboutUS />
      <AboutUSsectio1 />
      <Footer />
    </div>
  );
};

export default Aboutus;
