import React from "react";
import { Container } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import HeaderImage from "../../images/team.png";
import HeaderImage3 from "../../images/projectsupport.png";

const Aboutbbts = () => {
  return (
    <div className="BBT---Header BBT---teamS">
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          className="py-5 mt-5"
        >
          <Grid xs={12} sm={4} className="text-left mt-5 mt-sm-0 px-5">
            <img
              src={HeaderImage}
              alt="Header Image"
              className="mt-sm-5 mt-md-0  img-fluid w-100"
              style={{ maxWidth: 550 }}
            />
          </Grid>
          <Grid xs={12} sm={4} style={{}} className="pt-5 px-5">
            <h1 style={{ paddingTop: 31 }}>
              <span style={{ color: "#ffffff" }}>Our specialty</span>&nbsp;
            </h1>
            <p style={{ color: "#ffffff", maxWidth: "469px" }}>
              Our lean and agile team of developers, designers, content writers
              and marketers comprehend the client’s requirements and the overall
              situation, and deliver the projects on priority basis. We bring
              extensive cross-industry experience with productive and creative
              capabilities.
            </p>
            <button
              style={{
                backgroundColor: "#3dbbed",
                padding: "10px 34px",
                border: "none",
                color: "#ffffff",
              }}
            >
              Join us
            </button>
          </Grid>
          <Grid xs={12} sm={4} className="text-right mt-5 pt-5 mt-sm-0 px-5">
            <img
              src={HeaderImage3}
              alt="Header Image"
              className="mt-sm-5 mt-md-5 pt-5  img-fluid w-100"
              style={{ maxWidth: 550 }}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Aboutbbts;
