import React from "react";
import clsx from "clsx";
import { Grid } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

import logo from "../../images/footerlogo.png";
import medium from "../../images/medium.png";
import telegram from "../../images/telegram.png";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  logo: {
    maxWidth: 200,
  },
  dashboardBtn: {
    maxWidth: 50,
  },
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 20,
    backgroundColor: "#ffffff",
  },
  links: {
    color: "#fff",
    padding: "0px 8px",
    fontWeight: 700,
    textTransform: "uppercase",
    "&:hover": {
      color: "#f4f4f4",
      TextDecoder: "none",
    },
  },
}));

const Menu = () => {
  const classes = useStyles();

  return (
    <div
      className={classes.root + " mt-5 pb-3 "}
      style={{ padding: "14px 49px" }}
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        className="BBT---About "
      >
        <Grid
          xs={12}
          sm={4}
          className="text-left "
          style={{ padding: "33px 24px" }}
        >
          <div>
            <Typography variant="h6" noWrap className="pl-2 text-light">
              <Link to="/" className={classes.links}>
                <img
                  src={logo}
                  alt="logo"
                  className={classes.logo + " img-fluid"}
                />
              </Link>
            </Typography>
          </div>
        </Grid>
        <Grid
          xs={12}
          sm={4}
          className="text-center px-5"
          style={{ padding: "33px 85px" }}
        >
          <div>
            <Typography variant="h6" noWrap className="pl-2">
              <Link to="/" className="">
                <Button
                  style={{
                    padding: "10px 20px",
                    backgroundColor: "#025ec5",
                    color: "#ffffff",
                  }}
                >
                  <span style={{ color: "#ffffff" }}>Contact</span>
                </Button>
              </Link>
            </Typography>
          </div>
        </Grid>
        <Grid
          xs={12}
          sm={4}
          className="text-center px-5"
          style={{ padding: "33px 85px" }}
        >
          <div className="text-right" style={{ fontSize: "12px" }}>
            <p>
              2020 BBT<br></br>
              MAIN ST,G-12/1,IQBAL TOWN, NIAZI ARCADE, ISLAMABAD,<br></br>
              ISLAMABAD CAPITAL TERRITORY,PAKISTAN<br></br>
            </p>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Menu;
