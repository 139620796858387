import React from "react";
import { Container } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import abuspic from "../../../images/menupic.png";
import last from "../../../images/lastpage2ndpic.png";

const Aboutsection2 = () => {
  return (
    <div id="about">
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          className="BBT---About "
        >
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            className="BBT---About "
            style={{
              position: "relative",
              top: "-100px",
            }}
          >
            <Grid
              xs={12}
              sm={6}
              className="text-center px-5"
              style={{ padding: "33px 85px" }}
            >
              <img
                src={abuspic}
                alt="About Image"
                className="img-fluid w-100"
              />
            </Grid>
            <Grid xs={12} sm={6} className="text-left px-4  ">
              <div
                style={{
                  backgroundColor: "#ffffff",
                  padding: "41px 31px",
                  fontSize: "11px",
                }}
              >
                <h2>GET IN TOUCH</h2>
                <h5>ADDRESS</h5>
                <p>
                  MAIN ST,G-12/1,IQBAL TWOM, NIAZI ARCADE,ISLAMABAD,ISLAMABAD
                  CAPITAL TERRITORY, PAKISTAN
                </p>
                <h5>MAIL</h5>
                <p>info@beardbrotherstechnologies.com</p>
                <h5>PHONE</h5>
                <p>+92 323 5254400</p>
              </div>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            className="BBT---About py-5 "
          >
            <Grid xs={12} sm={6} className="text-left px-4  ">
              <div
                style={{
                  backgroundColor: "#ffffff",
                  padding: "67px 25px",
                  fontSize: "17px",
                  fontWeight: 800,
                }}
              >
                <h2 className="text-center pb-2">Contact Form</h2>
                <form>
                  <div style={{ display: "flex" }} className="py-3">
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      className="BBT---About "
                    >
                      <Grid xs={12} sm={6} className="text-left ">
                        <span className="mr-1">
                          <label>FIRST NAME</label>
                          <br></br>
                          <input
                            type="text"
                            placeholder="FIRST NAME"
                            style={{ height: " 30px", width: "100%" }}
                          ></input>
                          <br></br>
                        </span>
                      </Grid>
                      <Grid xs={12} sm={6} className="text-left">
                        <span className="ml-1">
                          <label>Last NAME</label>
                          <br></br>
                          <input
                            type="text"
                            placeholder="Last NAME"
                            className="ml-1"
                            style={{ height: " 30px", width: "100%" }}
                          ></input>
                        </span>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="py-3">
                    <label>Email</label>
                    <br></br>
                    <input
                      type="email"
                      style={{ width: "100%", height: " 30px" }}
                      placeholder="Email"
                    ></input>
                    <br></br>
                  </div>
                  <div className="py-3">
                    <label>MESSAGE</label>
                    <br></br>
                    <textarea
                      type="email"
                      style={{
                        width: "100%",
                        height: " 150px",
                        borderColor: "#b4b4b4",
                      }}
                    ></textarea>
                  </div>
                </form>
              </div>
            </Grid>
            <Grid xs={12} sm={6} className="text-center pt-5  ">
              <img
                src={last}
                alt="About Image"
                className="img-fluid w-100"
                style={{ padding: "25px 81px" }}
              />
            </Grid>

            <Grid xs={12} sm={12} className="text-left pt-5  ">
              <h1
                style={{ paddingTop: 31, fontWeight: "800" }}
                className="text-left"
              >
                <span>PARTNER WITH US</span>&nbsp;
              </h1>
              <p style={{ maxWidth: "704px" }} className="text-left">
                Find out how BBT can help you tackle your biggest project-based
                challenges and queries in the most proficient manner.
              </p>
              <button
                style={{
                  backgroundColor: "#3dbbed",
                  padding: "10px 34px",
                  border: "none",
                  color: "#ffffff",
                  fontWeight: "800",
                }}
              >
                START YOUR PROJECT
              </button>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Aboutsection2;
