import React from "react";
import { Grid } from "@material-ui/core";
import "./cardStyle.css";

const FeatureCard = (props) => {
  return (
    <Grid
      xs={12}
      md={3}
      className="BBT---FeatureCardsMain mx-sm-3 pt-3 mt-5"
      style={{ minHeight: "544px" }}
    >
      <div className="BBT---FeatureCards px-4  py-5 mx-auto">
        <img
          src={props.img}
          className="img-fluid d-none d-sm-block mx-auto"
          alt="Card Icon Missing"
        />
        <div className="mt-md-4 pt-1" style={{ alignItems: "flex-end" }}>
          <img
            src={props.img}
            className="img-fluid d-sm-none mb-3"
            alt="Card Icon Missing"
          />
          <h2 className="text-uppercase mb-3 m-0">{props.title}</h2>
          <p
            className="text-light mb-0"
            style={{ maxWidth: 900, fontSize: "13px" }}
          >
            {props.details1}
          </p>
        </div>
      </div>
    </Grid>
  );
};

export default FeatureCard;
