import React from "react";
import { Container } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import HeaderImage from "../../images/homepagemenupic.png";
import Menus from "../Menu/menu";

const Aboutbbts = () => {
  return (
    <div className="BBT---Header backgroungImageBBT">
      <Menus />
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          className="py-5"
        >
          <Grid xs={12} sm={7} style={{}}>
            <h2 style={{ paddingTop: 31 }}>
              <span
                style={{
                  color: "#ffffff",
                  textTransform: "uppercase",
                  fontWeight: "800",
                }}
              >
                BBT IS A ONE-STOP ENTERPRISE FOR providing state-of-the-art
                services.
              </span>
              &nbsp;
            </h2>
            <p style={{ color: "#ffffff", maxWidth: "469px" }}></p>
          </Grid>
          <Grid xs={12} sm={5} className="text-center mt-5 mt-sm-0">
            <img
              src={HeaderImage}
              alt="Header Image"
              className="mt-sm-5 mt-md-0  img-fluid w-100"
              style={{ maxWidth: 550 }}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Aboutbbts;
