import React from "react";
import { Container } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import Menus from "../../Menu/menu";

const AboutUs = () => {
  return (
    <div className="BBT---Header BBTcontact pb-5">
      <Menus />
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          className="py-5"
        ></Grid>
      </Container>
    </div>
  );
};

export default AboutUs;
