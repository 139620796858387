import React from "react";
import Header from "../../section/Header/header";
import Feature from "../../section/Feature/feature";
import Footer from "../../components/Footer";

const Home = () => {
  return (
    <div>
      <Header />
      <Feature />
      <Footer />
    </div>
  );
};

export default Home;
