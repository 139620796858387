import React from "react";
import { Container } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import aboutimg from "../../images/Aboutgraphics.png";
import web from "../../images/webandapp.png";
import mar from "../../images/marketing.png";
const About = () => {
  return (
    <div id="about">
      <Container maxWidth="lg">
        <Grid xs={12} className="text-center"></Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          className="BBT---About py-5 "
        >
          <Grid xs={12} sm={6} className="text-left py-sm-5 px-4 ">
            <h3 className="pt-5 mt-5">Blockchain:</h3>
            <p className="pt-md-4 my-4" style={{ maxWidth: 450, fontSize: 15 }}>
              Our team is renowned to be a one-stop solution for availing highly
              advanced, secure and reliable blockchain solutions from our team
              of experts.
            </p>
          </Grid>
          <Grid xs={12} sm={6} className="text-center pt-3 ">
            <img src={aboutimg} alt="About Image" className="img-fluid w-100" />
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            className="BBT---About py-5 "
          >
            <Grid xs={12} sm={6} className="text-center pt-3  ">
              <img src={web} alt="About Image" className="img-fluid w-100" />
            </Grid>
            <Grid xs={12} sm={6} className="text-left py-sm-5 px-4  ">
              <h3 className="pt-5 mt-5">Web and App development:</h3>
              <p
                className="pt-md-4 my-4"
                style={{ maxWidth: 450, fontSize: 15 }}
              >
                We develop, design, and craft the websites and apps according to
                the client’s needs and requirements. We handle and deliver our
                projects with utmost care.
              </p>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            className="BBT---About py-5 "
          >
            <Grid xs={12} sm={6} className="text-left py-sm-5 px-4 ">
              <h3 className="pt-5 mt-5">Marketing:</h3>
              <p
                className="pt-md-4 my-4"
                style={{ maxWidth: 450, fontSize: 15 }}
              >
                Our strategies and analysis has helped many ambitious brands
                improve their online presence and search visibility. We provide
                an overall services-profile including SEO, client engagement,
                social-media management, etc.
              </p>
            </Grid>
            <Grid xs={12} sm={6} className="text-center pt-3 ">
              <img src={mar} alt="About Image" className="img-fluid w-100" />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default About;
