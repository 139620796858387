import React from "react";
import { Container } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import Menus from "../../Menu/menu";

const AboutUs = () => {
  return (
    <div className="BBT---Header about pb-5">
      <Menus />
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          className="py-5"
        >
          <Grid xs={12} sm={12}>
            <h1 style={{ paddingTop: 31 }}>
              <span style={{ color: "#ffffff" }}>
                Building our reputation <br></br> through noteworthy work-ethics
              </span>
              &nbsp;
            </h1>
            <p style={{ color: "#ffffff", maxWidth: "704px" }}>
              We provide diverse services and products including but not limited
              to; blockchain solutions, web and app development, marketing, and
              print media. The powerhouse behind our products is spread out
              globally with offices in Islamabad (Pakistan), Quito (Ecuador) and
              Gujranwala (Pakistan).
            </p>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default AboutUs;
