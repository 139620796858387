import React from "react";
import { Container } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import FeaturesCard from "../../components/FeaturesCard/featuresCard";
import fish from "../../images/fish.png";
import burn from "../../images/burn.png";
import sp from "../../images/sprint.png";
import liquidity from "../../images/liquidity.png";
import community from "../../images/community.png";

const Feature = () => {
  return (
    <div id="features">
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className=" position-relative"
          style={{ top: "-94px" }}
        >
          <FeaturesCard
            img={burn}
            title="Apps:"
            details1="We’re proficient in developing high-quality apps that would ensure a sustainable and reliable long-term success. We build, test, and deploy highly competitive apps with such attributes that would make those apps stand out from the rest."
          />
          <FeaturesCard
            img={liquidity}
            title="Blockchain Smart Contracts:"
            details1="Our company has been delivering effective and authentic blockchain related solutions to many ambitious companies for a long time now. We have proven expertise in providing turnkey blockchain solutions that helps our clients achieve flexibility and scalability.  "
          />
          <FeaturesCard
            img={fish}
            title="Product Designs:"
            details1="We conceptualize and evaluate our client’s ideas in a tangible form that makes their companies look up to the present day standards. We blend our client’s needs with their business goals to help brands make consistently successful products."
          />
          <FeaturesCard
            img={community}
            title="Websites:"
            details1="We provide a wide range of professional services related to web developing and designing. Our vast area of expertise regarding web developing helps us in providing our clients with complete and comprehensive technical solutions. "
          />
          <FeaturesCard
            img={sp}
            title="Print Media"
            details1="Our team has always been a part of print publications as print media holds everlasting importance in many households, companies, and big firms. We extract authentic information for top-grossing topics, which really makes us stand out from the rest of the publishers."
          />
        </Grid>
      </Container>
    </div>
  );
};

export default Feature;
