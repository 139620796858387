import React from "react";
import Footer from "../../components/Footer";
import ContactUS from "../../section/Contact/contactus/ContactUs";
import ContactUSsectio1 from "../../section/Contact/contactus/Contactheader";

const Contact = () => {
  return (
    <div>
      <ContactUS />
      <ContactUSsectio1 />
      <Footer />
    </div>
  );
};

export default Contact;
