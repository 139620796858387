import React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

import logo from "../../images/logo.png";
import dashboardBtn from "../../images/menuBtn.png";

const useStyles = makeStyles((theme) => ({
  logo: {
    maxWidth: 200,
  },
  dashboardBtn: {
    maxWidth: 150,
  },
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "unset",
  },
  links: {
    color: "#fff",
    padding: "0px 12px",
    fontWeight: 700,
    textTransform: "uppercase",
    "&:hover": {
      color: "#f4f4f4",
      TextDecoder: "none",
    },
  },
}));

const Menu = () => {
  const classes = useStyles();

  return (
    <div className={classes.root} style={{ padding: "14px 49px" }}>
      <div>
        <Typography variant="h6" noWrap className="pl-2 text-light">
          <Link to="/" className={classes.links}>
            <img src={logo} alt="logo" className={classes.logo} />
          </Link>
        </Typography>
      </div>
      <div style={{ color: "red !important" }}>
        <a href="/" className={classes.links} style={{ fontSize: "12px" }}>
          Home
        </a>
        <a
          href="/industry"
          className={classes.links}
          style={{ fontSize: "12px" }}
        >
          Industry
        </a>
        <a
          href="/experience"
          className={classes.links}
          style={{ fontSize: "12px" }}
        >
          Experiences
        </a>
        <a href="/About" className={classes.links} style={{ fontSize: "12px" }}>
          About us
        </a>
        <a
          href="/contactus"
          className={classes.links}
          style={{ fontSize: "12px" }}
        >
          Contact
        </a>
      </div>
    </div>
  );
};

export default Menu;
