import React from "react";
import { Container } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import HeaderImage from "../../images/mainimage.png";
import Menus from "../Menu/menu";

const Header = () => {
  return (
    <div className="BBT---Header SolutionsIndustries">
      <Menus />
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          className="py-5"
        >
          <Grid xs={12} sm={7} style={{}}>
            <h1 style={{ paddingTop: 31 }}>
              <span style={{ color: "#ffffff" }}>Solution & Industries</span>
              &nbsp;
            </h1>
            <p style={{ color: "#ffffff" }}>
              We provides leading companies with Industry-specific solutions and{" "}
              <br></br>
              analysis to make their workflow easier,faster and more efficient.
            </p>
          </Grid>
          <Grid xs={12} sm={5} className="text-center mt-5 mt-sm-0">
            <img
              src={HeaderImage}
              alt="Header Image"
              className="mt-sm-5 mt-md-0  img-fluid w-100"
              style={{ maxWidth: 550 }}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Header;
