import React from "react";
import Header from "../../section/AboutBBT/about";
import Team from "../../section/AboutBBT/teamBBT";
import Feature from "../../section/Featurebbt/featurebbt";
import Footer from "../../components/Footer";

const AboutBBT = () => {
  return (
    <div>
      <Header />
      <Feature />
      <Team />
      <Footer />
    </div>
  );
};

export default AboutBBT;
