import React from "react";
import { Container } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import Menus from "../Menu/menu";

const Experiences = () => {
  return (
    <div className="BBT---Header backgroungImage3--BBtexperience">
      <Menus />
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          className="py-5"
        >
          <Grid xs={12} sm={12} style={{}}>
            <h1 style={{ paddingTop: 31 }}>
              <span style={{ color: "#ffffff" }}>Our Experience</span>&nbsp;
            </h1>
            <p style={{ color: "#ffffff" }}>
              Our team is composed of highly diligent personnel, with most
              having substantial work experience <br></br> in diverse areas of
              Information Technology. Our common goal has always been the same:
              To fulfill our <br></br> customer’s expectations through authentic
              customer satisfaction, and top-notch work ethics.
            </p>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Experiences;
