import React from "react";
import { Container } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import abimg from "../../../images/aboutus.png";
import fbimg from "../../../images/fab.png";
import linkimg from "../../../images/linkedin.png";
import gooimg from "../../../images/g.png";
import twittimg from "../../../images/twitter.png";
import abuspic from "../../../images/aboutuspic.png";

const Aboutsection2 = () => {
  return (
    <div id="about">
      <Container maxWidth="lg">
        <Grid xs={12} className="text-right">
          <img
            src={abimg}
            alt="About Image"
            className="img-fluid BBT---main-pic"
          />
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          className="BBT---About "
        >
          <Grid xs={12} sm={12} className="text-left pb-5  px-4 ">
            <h3 className="">ABOUT US:</h3>
            <p className="pt-2" style={{ maxWidth: "789px" }}>
              Incepted in 2020, Beard Brothers Technologies (Pvt) Ltd is a
              renowned blockchain, web and app development, and marketing
              company that helps leading companies solve critical business
              challenges and accelerate innovation through our industry-specific
              analysis, profound technical skills, and responsive management.
            </p>

            <p style={{ maxWidth: "789px" }}>
              Our team is composed of highly diligent personnel, with most
              having substantial work experience in diverse areas of Information
              Technology. Our common goal has always been the same: To fulfill
              our customer’s expectations through authentic customer
              satisfaction, and top-notch work ethics.
            </p>
            <div>
              {/* <a href="" className='mx-1'>
                  <img src={fbimg} alt="About Image" className="img-fluid "  />
                  </a> */}

              <a
                href="https://www.linkedin.com/company/beard-brothers-technologies/"
                className="mx-1"
              >
                <img src={linkimg} alt="About Image" className="img-fluid " />
              </a>

              {/* <a href="" className='mx-1'>
                  <img src={gooimg} alt="About Image" className="img-fluid "  />
                  </a> */}

              {/* <a href="" className='mx-1'>
                  <img src={twittimg} alt="About Image" className="img-fluid "  />
                  </a> */}
            </div>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            className="BBT---About py-5 "
          >
            <Grid xs={12} sm={6} className="text-center pt-5  ">
              <img
                src={abuspic}
                alt="About Image"
                className="img-fluid w-100 pt-5"
              />
            </Grid>
            <Grid xs={12} sm={6} className="text-left px-4  ">
              <h3 className=" ">Our value</h3>
              <h6 className="mt-5" style={{ fontWeight: 800 }}>
                DEDICATION
              </h6>
              <p className=" mb-4" style={{ maxWidth: "459px", fontSize: 15 }}>
                We are dedicated to our clients. Your needs and problems always
                come first, and only with a deep understanding of your business
                can we solve those problems.
              </p>
              <h6 style={{ fontWeight: 800 }}>CREDIBILITY</h6>
              <p className=" mb-4" style={{ maxWidth: "459px", fontSize: 15 }}>
                Data science is a complex and dynamic industry that is often
                misunderstood. That is why our credibility is a cornerstone of
                our identity.
              </p>
              <h6 style={{ fontWeight: 800 }}>RIGOR</h6>
              <p className=" mb-4" style={{ maxWidth: "459px", fontSize: 15 }}>
                Each step of our process is defined by rigor. We validate the
                results before sharing them, and we are straightforward with
                clients when we encounter challenges to their data.
              </p>
              <h6 style={{ fontWeight: 800 }}>COLLABORATION</h6>
              <p className=" mb-4" style={{ maxWidth: "459px", fontSize: 15 }}>
                Collaboration flows strongly between clients and our strong
                team. Internally, we share what we know freely, and we are never
                afraid to ask for mutual help.
              </p>
              <h6 style={{ fontWeight: 800 }}>EFFICIENCY</h6>
              <p className=" mb-4" style={{ maxWidth: "459px", fontSize: 15 }}>
                We care about scalability and efficiency as much as our
                customers do. From our project management tools to our
                development process to our documentation, effectiveness is at
                the core of what we do.
              </p>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Aboutsection2;
