import React from "react";
import About from "../../section/About/about";
import Footer from "../../components/Footer";
import Experiences from "../../section/Experiences/experiences";

const Experiences1 = () => {
  return (
    <div>
      <Experiences />
      <About />
      <Footer />
    </div>
  );
};

export default Experiences1;
