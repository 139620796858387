import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";
import Home from "./pages/home/home";
import Experiences from "./pages/home/experience";
import About from "./pages/home/aboutUs";
import AboutBBT from "./pages/home/aboutbbt";
import ContactBBT from "./pages/home/contact";
import PageNotFound from "./pages/404/error";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route path="/industry" exact component={Home} />
          <Route path="/experience" exact component={Experiences} />
          <Route path="/About" exact component={About} />
          <Route path="/" exact component={AboutBBT} />
          <Route path="/contactus" exact component={ContactBBT} />
          <Route component={PageNotFound} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
