import React from "react";
import { Container } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import FeaturesCard from "../../components/FeaturesCardBBT/featuresCardbbt";
import burn from "../../images/aboutus1stpic.png";
import liquidity from "../../images/aboutus2ndpic.png";

const Feature = () => {
  return (
    <div id="features">
      <Container maxWidth="lg">
        <h3 className="pt-5"></h3>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className=" position-relative"
        >
          <FeaturesCard
            style={{ position: "relative", top: "-183px" }}
            img={burn}
            title="What we do"
            details1="We provide leading companies with Industry-specific solutions and analysis to make their workflow easier, faster and more efficient."
          />
          <FeaturesCard
            img={liquidity}
            title="Who we help"
            details1="We have worked with a gamut of clients across diverse companies on a global scale, ranging from Information and technology, to gaming, ride-hailing, e-commerce, and blockchain."
          />
        </Grid>
      </Container>
    </div>
  );
};

export default Feature;
